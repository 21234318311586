import React, { useCallback, useEffect, useState } from "react"
import logo from "./../../assets/logo.svg"
import { Button, TextField } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import {
  checkIfIsAppLink,
  getUrlParam,
  transformPhoneNumber,
  validateEmail,
  validateEmailAddressWithSendGrid,
  validatePhoneNumber,
} from "../../utils/Util"
import { useAuth } from "../../providers/AuthProvider"
import Stores from "./Stores"
import { Loader } from "../common/Loader"

const Login = () => {
  const navigate = useNavigate()
  const { checkAuth, login, generateOTP, postOTPCode, isAuthenticated } = useAuth()
  const [loggingIn, setLoggingIn] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [noPermissionsMessage, setNoPermissionsMessage] = useState(false)
  const [input, setInput] = useState("")
  const [validInput, setValidInput] = useState<Boolean>(true)
  const [appLink, setAppLink] = useState<Boolean>(false)
  const [canVerify, setCanVerify] = useState(false)
  const [otpCode, setOtpCode] = useState("")
  const [showResend, setShowResend] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const token = getUrlParam("token") as string
    const email = getUrlParam("email") as string
    const phoneNumber = getUrlParam("phone_number") as string
    const isAppLink = checkIfIsAppLink()
    if (!isAppLink) {
      const doWork = async () => {
        setLoggingIn(true)
        if (!!token && (!!email || !!phoneNumber)) {
          await login(!!email ? { type: "email", email, token } : { type: "sms", phoneNumber, token }, controller.signal)
          setNoPermissionsMessage(true)
        } else {
          if (!!email || !!phoneNumber) {
            setInput(email ?? phoneNumber)
          }
          setLoggingIn(await checkAuth(controller.signal))
        }
      }

      doWork().catch((e) => {
        console.error("[Login] error: ", e)
      })

      return () => controller.abort()
    } else {
      setAppLink(true)
    }
  }, [checkAuth, login])

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  }, [isAuthenticated, navigate])

  const handleLogin = useCallback(
    async (e: any) => {
      e.preventDefault()

      const vemail = validateEmail(input)
      const vphone = !vemail ? validatePhoneNumber(input) : false
      setValidInput(vemail || vphone)

      if (vemail) {
        const validated = await validateEmailAddressWithSendGrid(input)

        if (!validated) {
          setValidInput(false)

          return
        }

        setMessage(true)

        return await generateOTP(input.toLowerCase(), "email")
      }

      if (vphone) {
        try {
          let phone = transformPhoneNumber(input)
          setInput(phone)
          setMessage(true)
          await generateOTP(phone, "sms")
        } catch (error) {
          alert("Something went wrong, please try again")
        }
      }
    },
    [input, generateOTP]
  )

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value.toLocaleLowerCase())
  }

  const handleVerifyOTP = async () => {
    try {
      if (!validInput) return

      setTimeout(() => {
        setShowResend(true)
      }, 20000)

      setLoading(true)

      const vemail = validateEmail(input)
      const vphone = !vemail ? validatePhoneNumber(input) : false
      const phone = vphone ? transformPhoneNumber(input) : undefined

      const res = await postOTPCode(phone ? phone : input, otpCode)

      if (res?.status === 200) {
        await login({ type: vphone ? "sms" : "email", phoneNumber: phone ?? "", email: input ?? "", token: res.token })
      } else {
        alert("Invalid OTP code, please try again")
      }
    } catch (error: any) {
      if (error?.message?.includes("permissions")) {
        setMessage(false)
        setNoPermissionsMessage(true)

        return
      }

      alert("An error occurred while validating your OTP Code, please try again")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gradient-to-b from-case-grad-from to-case-grad-to">
      {isLoading && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}

      <div className="m-auto p-6 lg:max-w-xl ">
        <div className="mb-8">
          <img src={logo} alt="logo" className="w-25" />
        </div>
        {appLink ? (
          <Stores />
        ) : message ? (
          <div>
            <h2 className="mb-8 text-center text-xl" style={{ color: "white" }}>
              A 6-digit code has been sent to {input}
            </h2>

            <div className="my-6 flex w-full flex-col items-center justify-center">
              <input
                autoComplete="one-time-code"
                className="h-12 w-4/5 rounded-md bg-white text-center text-lg font-semibold shadow-md"
                placeholder="Paste OTP Code here"
                value={otpCode}
                maxLength={6}
                inputMode="numeric"
                onChange={(e) => {
                  setOtpCode(e.target.value)

                  setCanVerify(e.target.value.length === 6)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleVerifyOTP()
                  }
                }}
              />

              {showResend && (
                <div className="my-4 flex w-full flex-col items-center justify-center text-lg text-white">
                  <p>
                    Didn't receive the code?{" "}
                    <span
                      className="cursor-pointer font-semibold hover:underline"
                      onClick={(e) => {
                        setShowResend(false)
                        setOtpCode("")
                        handleLogin(e)
                      }}
                    >
                      Resend
                    </span>
                  </p>
                </div>
              )}

              <button
                onClick={handleVerifyOTP}
                disabled={!canVerify}
                className="mx-4 mt-4 flex w-4/5 transform items-center justify-center rounded-lg bg-[#0F2150] p-4 text-lg font-bold text-white shadow-md transition duration-300 ease-in-out hover:scale-105 hover:bg-[#0F2150] hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#0F2150] focus:ring-opacity-50 disabled:scale-100 disabled:opacity-50"
              >
                Verify
              </button>
            </div>
          </div>
        ) : noPermissionsMessage ? (
          <h2 className="mb-8 text-center text-xl" style={{ color: "white" }}>
            You do not have permissions to enter the Portal or the token has Expired.
          </h2>
        ) : loggingIn ? (
          <h2 className="mb-8 text-center text-xl" style={{ color: "white" }}>
            Validating
          </h2>
        ) : (
          <div>
            <h1 className="mb-8 text-center text-7xl" style={{ color: "white" }}>
              Welcome
            </h1>
            <h2 className="mb-8 text-center text-xl" style={{ color: "white" }}>
              Sign in with your phone number or email address
            </h2>
            <div className="mb-2 text-center">
              <TextField
                id={"outlined-helperText"}
                variant={"outlined"}
                placeholder="Phone number or email"
                size="small"
                onChange={inputChanged}
                value={input}
                type="text"
                helperText={!validInput ? "" : ""}
                style={{
                  backgroundColor: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                }}
                className="w-80"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin(e)
                  }
                }}
              />
              {!validInput && (
                <p className="text-red" style={{ color: "red" }}>
                  Please enter a valid email or phone number.
                </p>
              )}
            </div>
            <div className="mt-6 text-center">
              <Button
                disabled={!input.trim()}
                onClick={handleLogin}
                style={{
                  borderRadius: "100px",
                  backgroundColor: "#0F2150",
                  color: "#fff",
                }}
                className="w-80"
              >
                Sign-in
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
